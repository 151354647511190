import { API } from "aws-amplify"
import { updateBiest as updateBiestMutation } from "../graphql/mutations"

import fetchBiestListFarmer from "./fetchBiestListFarmer"

export default async function modifyBiest(setData, biest, farmer){
    delete biest["updatedAt"]
    delete biest["createdAt"]
    delete biest["__typename"]
    await API.graphql({
        query: updateBiestMutation,
        variables: { input: biest },
    });
    fetchBiestListFarmer(setData, farmer)
}
import React from "react"
import { Typography, Box, Button, TextField, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Paper } from "@mui/material"

export default function AddRemark({ setOpenOpmerking, newSample, setNewSample }) {


    function handleChange(event) {
        console.log(event.target.value)
        setNewSample(prevState => {
            return {
                ...prevState,
                [event.target.name]: event.target.value
            }
        })
    }

    return (
        <>
            <Box sx={{ margin: '20px' }}>
                <TextField
                    fullWidth
                    type="text"
                    display='flex'
                    variant='outlined'
                    id="toelichting"
                    label="Opmerking over bieststaal"
                    multiline
                    rows={10}
                    name='toelichting'
                    value={newSample.toelichting}
                    onChange={handleChange}
                />
                <br />
                <Button fullWidth display="flex" onClick={()=>setOpenOpmerking(false)}>opmerking toevoegen</Button>
            </Box>
        </>
    )
}
import React, { useState, useEffect } from "react"
import { Box, TextField, FormControl, InputLabel, ToggleButton, ToggleButtonGroup, Typography, Select, MenuItem, InputAdornment, Input, IconButton, Icon } from "@mui/material"
import { MoreVert as MoreVertIcon, ExpandMore } from '@mui/icons-material';


export default function KalfInfo({ newSample, setNewSample, biestSoort, setBiestSoort, setOpenFridge }) {



    function handleChange(event) {
        event.target.value = event.target.value.replace(/,/g, '.')
        setNewSample(prevState => {
            return {
                ...prevState,
                [event.target.name]: event.target.value
            }
        })
    }

    function handleSelect(event) {
        setBiestSoort(event.target.value)
    }

    let wrongKalfId = false
    let wrongVolume = false
    let wrongVolumeText = ''
    if (newSample.kalfId.includes(',')) { wrongKalfId = true }
    if (newSample.kalfId.includes('.')) { wrongKalfId = true }
    if (newSample.kalfId.includes('-')) { wrongKalfId = true }
    if (newSample.biestGedronken < 0) {
        wrongVolume = true
        wrongVolumeText = "onrealistisch melkvolume"
    }
    if (newSample.biestGedronken > 20) {
        wrongVolume = true
        wrongVolumeText = "onrealistisch melkvolume"
    }
    if (newSample.biestGedronken * 10 % 1 != 0) {
        wrongVolume = true
        wrongVolumeText = "rond af naar één cijfer na de komma"
    }

    return (

        <>

            <Box display="flex" justifyContent="space-between">

                <FormControl sx={{ mr: '30px' }} fullWidth variant="standard">
                    <InputLabel htmlFor="biestSelect" shrink required>
                        selecteer bieststaal
                    </InputLabel>
                    <Input
                        onClick={() => setOpenFridge(true)}
                        id="biestSelect"
                        value={biestSoort}
                        inputProps={{ readOnly: true, }}
                        endAdornment={
                            <InputAdornment position="end">
                                <Icon

                                    edge="end"
                                >
                                    <ExpandMore />
                                </Icon>
                            </InputAdornment>
                        }
                    />
                </FormControl>

                <TextField
                    disabled={!newSample.biestType}
                    error={wrongVolume}
                    id="biestGedronken"
                    label="gedronken biest (L)"
                    name="biestGedronken"
                    variant="standard"
                    fullWidth
                    value={newSample.biestGedronken}
                    type="text"
                    inputProps={{ step: 0.1, inputMode: 'decimal', }}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true, }}
                    helperText={wrongVolume && wrongVolumeText}
                />
            </Box>

            <br></br>
            <br></br>

            <Box display="flex" justifyContent="space-between" >
                <TextField
                    disabled={!newSample.biestType}
                    error={wrongKalfId}
                    required
                    id="kalfId"
                    label="kalfnummer"
                    name="kalfId"
                    variant="standard"
                    value={newSample.kalfId}
                    size="normal"
                    margin="normal"
                    type="number"
                    sx={{ mr: '30px' }}
                    inputProps={{ inputMode: 'numeric', }}
                    fullWidth
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true, }}
                    helperText={wrongKalfId && "De ingevoerde waarde is niet geldig. Controleer of de waarde geen speciale tekens bevat, zoals een punt (.), een spatie (,) of een negatief teken (-)."}
                />
                <Box justifyContent="space-between">
                    <Typography sx={{ position: 'relative', color: 'gray', top: '0px' }} variant='caption'>beheer kalf</Typography>

                    <ToggleButtonGroup disabled={!newSample.biestType} fullWidth exclusive color="primary" value={newSample.kalfBeheer} onChange={handleChange}>
                        <ToggleButton fullWidth size='small' name='kalfBeheer' value='aanhouden' >aanhouden</ToggleButton>
                        <ToggleButton fullWidth size='small' name='kalfBeheer' value='verkopen'>verkopen</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Box>

        </>
    )
}
import React from "react"
import { Box, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from "dayjs"

export default function BiestInfo({ newSample, setNewSample }) {


    function handleChange(event) {
        event.target.value = event.target.value.replace(/,/g, '.')
        setNewSample(prevState => {
            return {
                ...prevState,
                [event.target.name]: event.target.value
            }
        })
    }
    let wrongVolume = false
    let wrongVolumeText = ''
    let wrongBrix = false
    let wrongBrixText = ''
    if (newSample.biestVolume < 0) {
        wrongVolume = true
        wrongVolumeText = "onrealistisch melkvolume"
    }
    if (newSample.biestVolume * 10 % 1 !== 0) {
        wrongVolume = true
        wrongVolumeText = "rond af naar één cijfer na de komma"
    }
    if (newSample.biestBrixWaarde * 100 % 1 !== 0) {
        wrongBrix = true
        wrongBrixText = "rond af naar één cijfer na de komma"
    }

    return (
        <>
            <Box display="flex" justifyContent="space-between" >
                <TextField
                    error={wrongVolume}
                    id="biestVolume"
                    label="volume verzameld (L)"
                    name="biestVolume"
                    variant="standard"
                    fullWidth
                    value={newSample.biestVolume}
                    type="text"
                    inputProps={{ step: 0.1, inputMode: 'number', }}
                    sx={{ mr: '30px' }}
                    margin="normal"
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true, }}
                    helperText={wrongVolume && wrongVolumeText}
                />
                <TextField
                    error={wrongBrix}
                    required
                    id="biestBrixWaarde"
                    label="Brix (%)"
                    name="biestBrixWaarde"
                    type="text"
                    variant="standard"
                    fullWidth
                    value={newSample.biestBrixWaarde}
                    inputProps={{ step: 0.1, inputMode: 'decimal', }}
                    margin="normal"
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true, }}
                    helperText={wrongBrix && wrongBrixText}
                />
            </Box>

            <br></br>

            <Box display="flex" justifyContent="space-between" >
                <DatePicker
                    id='kalfDatum'
                    name='kalfDatum'
                    value={dayjs(newSample.kalfDatum)}
                    format='DD-MM-YYYY'
                    label='kalfdatum'
                    onChange={(newValue) => setNewSample((prevState) => ({ ...prevState, kalfDatum: newValue }))}
                    slotProps={{
                        textField: {
                            variant: 'standard',
                        },
                    }}
                />
            </Box>
            <br></br>
            <Box display="flex" justifyContent="space-between" >

                <Box justifyContent="space-between" >
                    <Typography sx={{ position: 'relative', color: 'gray', top: '-7px' }} variant='caption'>tijdstip biestgift na geboorte</Typography>

                    <ToggleButtonGroup exclusive fullWidth color="primary" value={newSample.biestTijdstip} onChange={handleChange}>
                        <ToggleButton size='small' name='biestTijdstip' value='<2u'>{'<2u'}</ToggleButton>
                        <ToggleButton size='small' name='biestTijdstip' value='2-6u'>{'2-6u'}</ToggleButton>
                        <ToggleButton size='small' name='biestTijdstip' value='>6u'>{'>6u'}</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Box>


        </>
    )
}
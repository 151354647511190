import React, { useState } from "react"
import { Fab, Dialog, Table, Typography, TableBody, Box, TableCell, TableContainer, TableHead, TableFooter, TableRow, Paper, Toolbar, IconButton } from "@mui/material"
import { Add as AddIcon, Close as CloseIcon, MoreVert as MoreVertIcon } from '@mui/icons-material'

import AddFridgeItem from "./AddFridgeItem"
import BiestFridgeCell from "./BiestFridgeCell"

export default function BiestFridgeTable({ biestList, setBiestList, selectedFarmer, setFridgeOpen }) {

    const [openItemAdder, setOpenItemAdder] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    function openMenu(event) {
        setAnchorEl(event.currentTarget)
    }


    const farmerList = biestList.filter((biest) => biest.boerEmail === selectedFarmer)
    const fridgeList = farmerList.filter((biest) => (biest.kalfId.length === 0 && !biest.scrapped))

    return (
        <>
            <Paper sx={{ overflow: 'hidden' }}>
                <Toolbar display='flex' justify="space-between">

                    <Typography variant="button" sx={{ mt: 1, flexGrow: 1 }} display="flex" justifyContent="center">
                        biestvoorraad
                    </Typography>
                    <IconButton sx={{ color: 'black' }} onClick={() => setFridgeOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <TableContainer sx={{ maxHeight: 400 }}>
                    <Table stickyHeader size='medium' aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Koe</TableCell>
                                <TableCell align="right">Datum</TableCell>
                                <TableCell align="right">Brix %</TableCell>
                                <TableCell align="right">Volume</TableCell>
                                {/* <TableCell sx={{ width: '10px' }} /> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fridgeList.map((biestSample) => 
                                <BiestFridgeCell key={biestSample.id} biestSample={biestSample} setBiestList={setBiestList} />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box display='flex' justifyContent='center' >
                    <Fab variant="extended" onClick={() => setOpenItemAdder(true)} size="medium" color="primary" sx={{ m: 2 }}>
                        <AddIcon sx={{ mr: 1 }} />
                        Biest toevoegen
                    </Fab>
                </Box>
            </Paper>
            <Dialog fullWidth sx={{ padding: '10px' }} open={openItemAdder} onClose={() => setOpenItemAdder(false)}>
                <AddFridgeItem setOpenItemAdder={setOpenItemAdder} setBiestList={setBiestList} selectedFarmer={selectedFarmer} />
            </Dialog>
        </>
    )
}
import React, { useEffect } from "react"
import { IconButton, Typography, Box, Button, TextField, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Paper } from "@mui/material"
import { ListSubheader, List, ListItemText, ListItemButton, ListItemIcon, Collapse } from "@mui/material"
import { ExpandLess, ExpandMore, StarBorder, Close as CloseIcon } from "@mui/icons-material"

export default function SelectBiest({ setOpenFridge, biestList, moederBiest, setMoederBiest, setNewSample, setBiestSoort }) {

    const fridgeList = biestList.filter((biest) => (biest.kalfId.length === 0 && biest.id !== moederBiest.id && !biest.scrapped))

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    function handleMoederBiestSelect() {
        setNewSample(moederBiest)
        setBiestSoort('Moederbiest')
        setOpenFridge(false)
    }

    function handleFridgeBiestSelect(id) {
        setNewSample(fridgeList.find((biest) => biest.id === id))
        setBiestSoort('biest uit voorraad')
        setOpenFridge(false)
    }

    useEffect(() => {
        setMoederBiest(biestList.reduce((a, b) => (a.updatedAt > b.updatedAt ? a : b)))
    }, [])

    return (
        <>
            <Paper >
                <List
                    subheader={
                        <>
                            <ListSubheader>
                                Selecteer bieststaal
                            </ListSubheader>
                        </>
                    }
                >
                    <ListItemButton disabled={moederBiest.scrapped} onClick={handleMoederBiestSelect}>
                        <ListItemText primary="Moederbiest" />
                    </ListItemButton>
                    <ListItemButton onClick={handleClick}>
                        <ListItemText primary="Biestvoorraad" />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <TableContainer sx={{ maxHeight: 180 }}>
                            <Table stickyHeader size='medium' aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Koe</TableCell>
                                        <TableCell align="right">Datum</TableCell>
                                        <TableCell align="right">Brix</TableCell>
                                        <TableCell align="right">Volume</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {fridgeList.map((item) => (
                                        <TableRow
                                            hover
                                            onClick={() => handleFridgeBiestSelect(item.id)}
                                            key={item.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {item.moederId}
                                            </TableCell>
                                            <TableCell align="right">{item.kalfDatum}</TableCell>
                                            <TableCell align="right">{item.biestBrixWaarde}</TableCell>
                                            <TableCell align="right">{item.biestVolume}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Collapse>

                </List>
            </Paper>
        </>
    )
}
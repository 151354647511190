import React, { useState } from "react"
import { Fab, Box } from "@mui/material"
import { Add as AddIcon } from '@mui/icons-material'
import AddSampleDialog from "./addSampleComponents/AddSampleDialog"


export default function AddSample({ biestList, setBiestList, selectedFarmer }) {

    const [open, setOpen] = useState(false)

    return (
        <>
            <Box display='flex' justifyContent='center' > 
                <Fab
                    onClick={() => setOpen(true)}
                    sx={{ position: 'fixed', top: window.innerHeight/2-24, width:'30%', minWidth:'220px'}}
                    variant="extended"
                    size="large"
                    color="primary"
                    aria-label="add"
                >
                    <AddIcon sx={{ mr: 1 }}/>
                    Registreer Kalving
                </Fab>
            </Box>

            <AddSampleDialog open={open} setOpen={setOpen} biestList={biestList} setBiestList={setBiestList} selectedFarmer={selectedFarmer} />
        </>
    )
}
import React, {useState} from "react"
import { Fab, Box } from "@mui/material"
import { Kitchen as KitchenIcon } from '@mui/icons-material'
import BiestFridge from "./OpenFridgeComponents/BiestFridge"

export default function OpenFridge({ biestList, setBiestList, selectedFarmer }) {

    const [open, setOpen] = useState(false)
    return (
        <>
            <Box display='flex' justifyContent='center' >
                <Fab
                    onClick={() => setOpen(true)}
                    sx={{ position: 'fixed', top: window.innerHeight / 2 + 48 , width:'30%', minWidth:'220px', paddingRight: '50px' }}
                    variant="extended"
                    size="large"
                    color="primary"
                    aria-label="add"
                >
                    <KitchenIcon sx={{ mr: 1 }}/>
                    diepvries biest
                </Fab>
            </Box>
            <BiestFridge fridgeOpen={open} setFridgeOpen={setOpen} biestList={biestList} setBiestList={setBiestList} selectedFarmer={selectedFarmer}/>
        </>
    )
}
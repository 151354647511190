import React from "react"
import {Drawer} from "@mui/material"
import BiestFridgeTable from "./BiestFridgeTable"

export default function BiestFridge({fridgeOpen, setFridgeOpen, biestList, setBiestList, selectedFarmer}) {


    return (
        <>
            <Drawer open={fridgeOpen} anchor='bottom' onClose={() => setFridgeOpen(false)} >
                <BiestFridgeTable biestList = {biestList} setBiestList={setBiestList} selectedFarmer = {selectedFarmer} setFridgeOpen = {setFridgeOpen}/>
            </Drawer>
        </>
    )
}
import React from "react"
import { Box, TextField, Checkbox, FormControlLabel, Button, Typography, Paper } from "@mui/material"
import { Add as AddIcon } from '@mui/icons-material'
import colorOfGradient from "../../../../functions/colorOfGradient"


export default function BiestResult({ newSample, setNewSample, biestSoort, setBiestSoort, setOpenFridge, setOpenOpmerking }) {

    const igg = Math.round(11.582 * newSample.biestBrixWaarde - 208.05)

    const advies = [
        "De Brix-waarde moet tussen 0 en 100% liggen. Gelieve een nieuwe meting te doen.",
        "Deze Brix-waarde is onverwacht laag. Voer de meting opnieuw uit.",
        `Deze biest is van slechte kwaliteit en bevat onvoldoende antistoffen voor een pasgeboren kalf.`,
        `Deze biest is van slechte kwaliteit en bevat onvoldoende antistoffen voor een pasgeboren kalf (= ${igg} g IgG per liter).`,
        `Deze biest is van minder goede kwaliteit en bevat onvoldoende antistoffen voor een pasgeboren kalf (= ${igg} g IgG per liter).`,
        `Deze biest is van goede kwaliteit en bevat voldoende antistoffen voor een pasgeboren kalf (${igg} g IgG per liter).`,
        `Deze biest is van zeer goede kwaliteit en bevat voldoende antistoffen voor een pasgeboren kalf (${igg} g IgG per liter).`,
        "Deze Brix-waarde is onverwacht hoog. Voer de meting opnieuw uit.",
        "De Brix-waarde moet tussen 0 en 100% liggen. Gelieve een nieuwe meting te doen."
    ]

    let textToDisplay = ""
    if (newSample.biestBrixWaarde < 0) {
        textToDisplay = advies[0]
    } else if (newSample.biestBrixWaarde < 10.0) {
        textToDisplay = advies[1]
    } else if (newSample.biestBrixWaarde < 18.1) {
        textToDisplay = advies[2]
    } else if (newSample.biestBrixWaarde < 20.0) {
        textToDisplay = advies[3]
    } else if (newSample.biestBrixWaarde < 22.3) {
        textToDisplay = advies[4]
    } else if (newSample.biestBrixWaarde < 25.0) {
        textToDisplay = advies[5]
    } else if (newSample.biestBrixWaarde < 40.0) {
        textToDisplay = advies[6]
    } else if (newSample.biestBrixWaarde < 100.1) {
        textToDisplay = advies[7]
    } else {
        textToDisplay = advies[8]
    }

    function handleScrapping(event) {
        setNewSample(prevState => {
            return {
                ...prevState,
                [event.target.name]: event.target.checked
            }
        })
    }

    let position = 0
    if (newSample.biestBrixWaarde > 10 && newSample.biestBrixWaarde < 45) {
        position += 2 + Math.round((newSample.biestBrixWaarde - 10) * 2.73)
    }

    return (
        <>
            
            <Paper
                sx={{
                    width: "100%",
                    height: "138px",
                    borderRadius: '10px',
                    padding: '10px',
                    borderColor: colorOfGradient(position),
                    borderStyle: 'solid',
                    borderWidth: 2,
                }}
            >
               <TextField
                
                variant='standard'
                id="outlined-read-only-input"
                label="Calfcheck advies"
                multiline
                rows={5}
                value={textToDisplay}
                inputProps={{ readOnly: true, className: 'textfield__input'}}
                InputProps={{ disableUnderline: true }}
                sx={{
                    width: "100%",
                    height: "118px",
                }}
            />
                {/* <Typography align='justify' variant='body2' sx={{ fontSize: '12px' }}>{textToDisplay} </Typography> */}

            </Paper>
            <Paper
                sx={{
                    position: 'relative',
                    left: `${position}%`,
                    borderRadius: '0px',
                    width: "2px",
                    height: "10px",
                    background: colorOfGradient(position),
                    opacity: position > 0 ? '100%' : '0%',


                }}
            />
            <Paper
                sx={{
                    width: "100%",
                    height: "10px",
                    borderRadius: '30px',
                    background: "linear-gradient(90deg, #F44336 29%, #E6E939 36%, #00A35E 43%)",
                    zIndex: 100000
                }}
            />
            

            <br></br>
            <br></br>
            <Box display="flex" justifyContent="space-between" >
                <Button startIcon={<AddIcon />} onClick={() => setOpenOpmerking(true)} size='small'>opmerking toevoegen</Button>
                <FormControlLabel control={<Checkbox size="small" name="scrapped" checked={newSample.scrapped} onChange={handleScrapping} inputProps={{ 'aria-label': 'controlled' }}
                />} label="verwijder bieststaal" />
            </Box>

        </>
    )
}
import React, { useState, useEffect } from "react"
import dayjs from "dayjs"
import { Amplify } from 'aws-amplify'
import { Dialog, Container, Box, Button, Stepper, Step, StepLabel, TextField } from "@mui/material"

import BiestInfo from "./BiestInfo"
import KoeInfo from "./KoeInfo"
import KalfInfo from "./KalfInfo"
import BiestResult from "./BiestResult"
import createBiest from '../../../../functions/createBiest'
import modifyBiest from '../../../../functions/modifyBiest'
import SelectBiest from "./SelectBiest"
import AddRemark from "./AddRemark"

export default function AddSampleDialog({ open, setOpen, biestList, setBiestList, sample, selectedFarmer }) {

    const initSample = {
        boerNaam: Amplify.Auth.user.attributes.name,
        boerEmail: Amplify.Auth.user.attributes.email,
        bedrijfId: "",
        boerderijNaam: "",
        bedrijfAdres: "",
        moederId: "",
        moederLeeftijd: "",
        kalfId: "",
        kalfDatum: dayjs(),
        kalfGeslacht: "",
        kalfBeheer: "",
        biestTijdstip: "",
        biestVolume: "",
        biestGedronken: "",
        biestBrixWaarde: "",
        biestIgGWaarde: 0,
        biestGedronkenTijdstip: "",
        bloedBrixWaarde: 0,
        bloedIgGWaarde: 0,
        toelichting: "",
        scrapped: false,
        moederDroogstand: dayjs(),
        biestType: "",
    }


    const [step, setStep] = useState(0)
    const [stepBiest, setStepBiest] = useState(0)
    const [newSample, setNewSample] = useState(initSample)
    const [biestSoort, setBiestSoort] = useState('')
    const [openOpmerking, setOpenOpmerking] = useState(false)
    const [openFridge, setOpenFridge] = useState(false)

    const [moederBiest, setMoederBiest] = useState("")


    useEffect(() => {
        if (sample) {
            setNewSample(sample)
        }
    }, [sample])

    function prepareSample() {
        setNewSample((prevState) => (
            {
                ...prevState,
                biestBrixWaarde: Number(prevState.biestBrixWaarde),
                biestIgGWaarde: Math.round(Number(prevState.biestBrixWaarde) * 11.582 - 208.05),
                ...(typeof prevState.kalfDatum === 'object') && { kalfDatum: prevState.kalfDatum.format('YYYY-MM-DD') },
                ...(typeof prevState.moederDroogstand === 'object') && { moederDroogstand: prevState.moederDroogstand.format('YYYY-MM-DD') },
            }))
        setStepBiest(1)
    }
    function sendSample() {
        if (newSample.id) {
            modifyBiest(setBiestList, newSample, selectedFarmer)
        } else {
            createBiest(setBiestList, newSample, selectedFarmer)
        }
        setOpen(false)
        setStep(0)
        setNewSample(initSample)
        setBiestSoort('')
    }

    function sendBiestSample() {
        createBiest(setBiestList, newSample, selectedFarmer)
        setStep(2)
        setStepBiest(0)
    }

    function cancelSample() {
        setOpen(false)
        setStep(0)
        setNewSample(initSample)
        setBiestSoort('')
    }

    let koeMissingInfo = true
    if (newSample.moederId) {
        koeMissingInfo = false
    }
    let biestMissingInfo = true
    if (newSample.biestBrixWaarde && newSample.kalfDatum) {
        biestMissingInfo = false
    }
    let kalfMissingInfo = true
    if (newSample.kalfId && biestSoort) {
        kalfMissingInfo = false
    }

    newSample.biestType = biestSoort

    return (
        <>
            <Dialog fullWidth open={open}>
                <Container sx={{ marginBottom: '10px' }}>
                    <br />
                    <Stepper activeStep={step} alternativeLabel>
                        <Step>
                            <StepLabel>koe</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>biest</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>kalf</StepLabel>
                        </Step>
                    </Stepper>
                    <br />
                    {step === 0 &&
                        <>
                            <KoeInfo newSample={newSample} setNewSample={setNewSample} />
                            <br />
                            <br />
                            <Box display="flex" justifyContent="space-between" >
                                <Button onClick={cancelSample}>annuleer</Button>
                                <Button disabled={koeMissingInfo} onClick={() => setStep(1)}>volgende</Button>
                            </Box>
                        </>
                    }
                    {step === 1 && stepBiest === 0 &&
                        <>
                            <BiestInfo newSample={newSample} setNewSample={setNewSample} />
                            <br />
                            <br />
                            <Box display="flex" justifyContent="space-between" >
                                <Button onClick={() => setStep(0)}>vorige</Button>
                                <Button disabled={biestMissingInfo} onClick={prepareSample}>volgende</Button>
                            </Box>
                        </>
                    }
                    {step === 1 && stepBiest === 1 &&
                        <>
                            <BiestResult newSample={newSample} setNewSample={setNewSample} biestSoort={biestSoort} setBiestSoort={setBiestSoort} setOpenFridge={setOpenFridge} setOpenOpmerking={setOpenOpmerking} />
                            <br />

                            <Box display="flex" justifyContent="space-between" >
                                <Button onClick={() => setStepBiest(0)}>vorige</Button>
                                <Button onClick={sendBiestSample}>volgende</Button>
                            </Box>
                        </>
                    }
                    {step === 2 &&
                        <>
                            <KalfInfo newSample={newSample} setNewSample={setNewSample} biestSoort={biestSoort} setBiestSoort={setBiestSoort} setOpenFridge={setOpenFridge} />
                            <br />
                            <Box display="flex" justifyContent="space-between" >
                                <Button onClick={cancelSample}>annuleer</Button>
                                <Button disabled={kalfMissingInfo} onClick={sendSample}>opslaan</Button>
                            </Box>
                        </>
                    }
                </Container>
            </Dialog>

            <Dialog fullWidth sx={{ padding: '10px' }} open={openOpmerking}>
               <AddRemark setOpenOpmerking={setOpenOpmerking} newSample={newSample} setNewSample={setNewSample}/>
            </Dialog>

            <Dialog fullWidth sx={{ padding: '10px' }} open={openFridge} onClose={() => setOpenFridge(false)}>
                <SelectBiest setOpenFridge={setOpenFridge} biestList={biestList} moederBiest={moederBiest} setMoederBiest={setMoederBiest} setNewSample={setNewSample} setBiestSoort={setBiestSoort}/>
            </Dialog>

        </>
    )
}
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBiest = /* GraphQL */ `
  query GetBiest($id: ID!) {
    getBiest(id: $id) {
      id
      boerNaam
      boerEmail
      bedrijfId
      boerderijNaam
      bedrijfAdres
      moederId
      moederLeeftijd
      kalfId
      kalfDatum
      kalfGeslacht
      kalfBeheer
      biestTijdstip
      biestVolume
      biestGedronken
      biestBrixWaarde
      biestIgGWaarde
      biestGedronkenTijdstip
      bloedBrixWaarde
      bloedIgGWaarde
      toelichting
      moederDroogstand
      scrapped
      biestType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBiests = /* GraphQL */ `
  query ListBiests(
    $filter: ModelBiestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBiests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        boerNaam
        boerEmail
        bedrijfId
        boerderijNaam
        bedrijfAdres
        moederId
        moederLeeftijd
        kalfId
        kalfDatum
        kalfGeslacht
        kalfBeheer
        biestTijdstip
        biestVolume
        biestGedronken
        biestBrixWaarde
        biestIgGWaarde
        biestGedronkenTijdstip
        bloedBrixWaarde
        bloedIgGWaarde
        toelichting
        moederDroogstand
        scrapped
        biestType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

import React, { useState } from "react"
import { Fab, Dialog, Table, Typography, TableBody, Box, TableCell, TableContainer, TableHead, TableFooter, TableRow, Paper, Toolbar, IconButton } from "@mui/material"
import { Add as AddIcon, Close as CloseIcon, MoreVert as MoreVertIcon } from '@mui/icons-material'
import dayjs from "dayjs"


export default function BiestFridgeCell({ biestSample, setBiestList }) {
   
    const [anchorEl, setAnchorEl] = useState(null);

    function openMenu(event) {
        setAnchorEl(event.currentTarget)
    }

    return (
        <TableRow
            key={biestSample.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row">
                {biestSample.moederId}
            </TableCell>
            <TableCell align="right">{dayjs(biestSample.kalfDatum).format('YYYY-MM-DD')}</TableCell>
            <TableCell align="right">{biestSample.biestBrixWaarde}</TableCell>
            <TableCell align="right">{biestSample.biestVolume}</TableCell>
            {/* <TableCell sx={{ fontSize: '12px', padding: 0 }}>
                <IconButton size='small' onClick={openMenu}>
                    <MoreVertIcon sx={{ fontSize: '15px' }} />
                </IconButton >
            </TableCell> */}
        </TableRow>
    )
}
import React, { useState, useEffect } from "react"
import { Box, Button, Dialog, Table, Typography, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Toolbar, IconButton, TextField } from "@mui/material"
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from "dayjs"
import { Amplify } from 'aws-amplify'

import createBiest from '../../../../functions/createBiest'

export default function AddFridgeItem({ setOpenItemAdder, setBiestList, selectedFarmer }) {

    const initSample = {
        boerNaam: Amplify.Auth.user.attributes.name,
        boerEmail: Amplify.Auth.user.attributes.email,
        bedrijfId: "",
        boerderijNaam: "",
        bedrijfAdres: "",
        moederId: "",
        moederLeeftijd: "",
        kalfId: "",
        kalfDatum: dayjs().format('YYYY-MM-DD'),
        kalfGeslacht: "",
        kalfBeheer: "",
        biestTijdstip: "",
        biestVolume: "",
        biestGedronken: "",
        biestBrixWaarde: "",
        biestIgGWaarde: 0,
        biestGedronkenTijdstip: "",
        bloedBrixWaarde: 0,
        bloedIgGWaarde: 0,
        toelichting: "",
        scrapped: false,
        moederDroogstand: dayjs(),
    }

    const [newSample, setNewSample] = useState(initSample)

    function handleChange(event) {
        setNewSample(prevState => {
            return {
                ...prevState,
                [event.target.name]: event.target.value
            }
        })
    }

    function sendItem() {
        createBiest(setBiestList, newSample, selectedFarmer)
        setOpenItemAdder(false)
    }

    return (
        <Paper sx={{ p: 3 }}>
            <Toolbar display='flex' justify="space-between">
                <Typography variant="button" sx={{ mt: 1, flexGrow: 1 }} display="flex" justifyContent="center">
                    bieststaal toevoegen
                </Typography>
            </Toolbar>
            <TextField
                required
                id="moederId"
                label="koenummer"
                name="moederId"
                variant="standard"
                value={newSample.moederId}
                size="normal"
                type="number"
                margin="normal"
                inputProps={{ inputMode: 'numeric', }}
                fullWidth
                onChange={handleChange}
                InputLabelProps={{ shrink: true, }}
            />
            <DatePicker
                id='kalfDatum'
                name='kalfDatum'
                value={dayjs(newSample.kalfDatum)}
                format='DD-MM-YYYY'
                label='kalfdatum'
                onChange={(newValue) => setNewSample((prevState) => ({ ...prevState, kalfDatum: newValue.format('YYYY-MM-DD') }))}
                margin="normal"
                slotProps={{
                    textField: {
                        variant: 'standard',
                    },
                }}
            />
            <TextField
                required
                id="biestBrixWaarde"
                label="Brix (%)"
                name="biestBrixWaarde"
                type="text"
                variant="standard"
                fullWidth
                value={newSample.biestBrixWaarde}
                inputProps={{ step: 0.1, inputMode: 'decimal', }}
                margin="normal"
                onChange={handleChange}
                InputLabelProps={{ shrink: true, }}
            />
            <TextField
                id="biestVolume"
                label="volume ingevroren (L)"
                name="biestVolume"
                variant="standard"
                fullWidth
                value={newSample.biestVolume}
                type="text"
                inputProps={{ step: 0.1, inputMode: 'number', }}
                sx={{ mr: '30px' }}
                margin="normal"
                onChange={handleChange}
                InputLabelProps={{ shrink: true, }}
            />

            <br></br>
            <br></br>

            <Box display="flex" justifyContent="space-between" >
                <Button onClick={() => setOpenItemAdder(false)}>annuleer</Button>
                <Button onClick={sendItem}>opslaan</Button>
            </Box>
        </Paper>
    )
}